import {
  Badge,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Fab,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Switch,
  Typography
} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {FormattedMessage, useIntl} from "react-intl";
import {graphQLApi} from "../../../services/GraphQLApi";
import {authRefresh, authUser, useAuthDispatch} from "../../../contexts/Auth";
import {Add, CheckSharp, Close, Edit, HelpOutline, Image, Warning} from "@material-ui/icons";
import EditForm from "../../../components/Form/EditForm";
import theme from "../../../assets/theme/theme";
import Tooltip from "@material-ui/core/Tooltip";
import ConfirmDialog from "../../../components/Dialogs/ConfirmDialog";

const ContainerCard = ({children}) => {
  return <Card style={{
    borderRadius: 0,
    borderTopLeftRadius: "0.725rem",
    borderBottomLeftRadius: "0.725rem",
    boxShadow: "none",
  }}>{children}</Card>
}
export default function Billing() {
  const intl = useIntl();
  const [showSubscriptionSelect, setShowSubscriptionSelect] = useState('');
  const [selectedSubscriptionIdxs, setSelectedSubscriptionIdxs] = useState({});
  const [subscriptions, setSubscriptions] = useState({});
  const [confirmSubscriptions, setConfirmSubscriptions] = useState(false);
  const [isSavingSite, setIsSavingSite] = useState(false);
  const [isSavingSubs, setSavingSubs] = useState(false);
  const defaultSite = {
    company: "",
    address: "",
    zip: "",
    city: "",
    country: "",
    logo: "",
    cvr: "",
    invoice_email: "",
    contact: "",
    phone: "",
    email: "",
    subscriptions: [],
  };
  const [originalSite, setOriginalSite] = useState(defaultSite);
  const [site, setSite] = useState(defaultSite);

  const initialValidation = {
    title: [],
    name: [],
    email: [],
    phone: [],
    locale: [],
    password: [],
    password_confirmation: [],
    site: {
      company: [],
      address: [],
      zip: [],
      city: [],
      country: [],
      logo: [],
      cvr: [],
      invoice_email: [],
      contact: [],
      phone: [],
      email: [],
      support: [],
      agreed: [],
    }
  };

  const [validation, setValidation] = useState(initialValidation);
  const setValidationFromErrors = (errors) => {
    if (Array.isArray(errors) && errors[0] && errors[0].hasOwnProperty('extensions') && errors[0].extensions.hasOwnProperty('validation')) {
      setValidation({...initialValidation, ...errors[0].extensions.validation});
    }
  };
  const client = new graphQLApi(useAuthDispatch(), null, {handleErrors: setValidationFromErrors});
  const userFields = 'id name title phone email locale ' +
    ' site{logo logo_uri company address zip city country cvr contact phone email invoice_email' +
    '  subscriptions{id subscription_id type_id use_automation renewed_at}' +
    ' }';
  useEffect(() => {
    setIsSavingSite(true);
    client.query('{' +
      'users(filter:{id:' + authUser().id + '}){data' +
      '{' + userFields + '}' +
      '}' +
      'subscriptions{id type_id type title feature_list automation_id limit price price_currency}' +
      '}').then(r => {
      if (r && r.hasOwnProperty('users')) {
        setOriginalSite({...defaultSite, ...r.users.data[0].site});
        let subs = {};
        r.subscriptions.forEach(s => {
          subs[s.id] = s;
        });
        let newSite = {...defaultSite, ...r.users.data[0].site};
        [{id: '82', key: 'storage', sId: '91'}, {id: '83', key: 'process', sId: '95'}].forEach(type => {
          let idx = newSite.subscriptions.findIndex(s => s.type_id === type.id);
          if (idx === -1) {
            newSite['subscription_' + type.key] = subs[type.sId];
            newSite.subscriptions.push({subscription_id: type.sId, type_id: type.id});
          } else {
            newSite['subscription_' + type.key] = subs[newSite.subscriptions[idx].subscription_id];
          }
        });
        setSite(newSite);
        setSubscriptions(subs);
        setSelectedSubscriptionIdxs({
          '82': r.subscriptions.filter(sub => sub.type_id === '82').findIndex(sub => sub.id === newSite.subscription_storage.id),
          '83': r.subscriptions.filter(sub => sub.type_id === '83').findIndex(sub => sub.id === newSite.subscription_process.id),
        });
      }
      setIsSavingSite(false);
    });
    return () => {
      setOriginalSite(defaultSite)
      setSite(defaultSite);
      setSubscriptions({});
    }
  }, []);

  const handleFileUpload = (field, file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = function () {
      let n = {};
      n[field] = file.name;
      n[field + "_data"] = reader.result;
      console.log(n);
      setSite({...site, ...n});
    };
  };

  const saveSite = () => {
    const data = {id: authUser().id, site: {...site}};
    delete data.site.logo_uri;
    delete data.site.subscriptions;
    delete data.site.support;
    delete data.site.subscription_storage;
    delete data.site.subscription_process;
    const vars = {
      id: "ID!",
      site: "SiteInput",
    };
    setIsSavingSite(true);
    client.mutation('user', vars, data, 'id').then(r => {
      setIsSavingSite(false);
      if (r && r.hasOwnProperty('response')) {
        authRefresh(true, false, authDispatch);
      }
    });
  }

  const authDispatch = useAuthDispatch();
  const saveSubscriptions = (confirmed) => {
    setConfirmSubscriptions(false);
    if (!confirmed) {
      return;
    }
    const data = {
      id: authUser().id,
      subscriptions: site.subscriptions.map(sub => ({
        subscription_id: sub.subscription_id,
        type_id: sub.type_id,
        use_automation: sub.use_automation,
      }))
    };
    const vars = {
      id: "ID!",
      subscriptions: "[SiteSubscriptionInput]",
    };
    setSavingSubs(true);
    client.mutation('user', vars, data, userFields).then(r => {
      setSavingSubs(false);
      if (r && r.hasOwnProperty('response')) {
        setOriginalSite({...r.response.site});
        authRefresh(true, false, authDispatch);
      }
    });
  }

  const fields = [
    {
      field: "company",
      label: intl.formatMessage({
        id: "sites.edit.label.company",
        defaultMessage: "Company name",
      }),
    },
    {
      field: "address",
      label: intl.formatMessage({
        id: "sites.edit.label.address",
        defaultMessage: "Address",
      }),
    },
    {
      field: "zip",
      label: intl.formatMessage({
        id: "sites.edit.label.zip",
        defaultMessage: "Zip",
      }),
    },
    {
      field: "city",
      label: intl.formatMessage({
        id: "sites.edit.label.city",
        defaultMessage: "City",
      }),
    },
    {
      field: "country",
      label: intl.formatMessage({
        id: "sites.edit.label.country",
        defaultMessage: "Country",
      }),
    },
    {
      column: 2,
      field: "cvr",
      label: intl.formatMessage({
        id: "sites.edit.label.cvr",
        defaultMessage: "VAT reg.",
      }),
    },
    {
      column: 2,
      field: "invoice_email",
      label: intl.formatMessage({
        id: "sites.edit.label.invoice_email",
        defaultMessage: "Invoice e-mail",
      }),
    },
    {
      column: 2,
      field: "contact",
      label: intl.formatMessage({
        id: "sites.edit.label.contact",
        defaultMessage: "Contact",
      }),
    },
    {
      column: 2,
      field: "phone",
      label: intl.formatMessage({
        id: "sites.edit.label.phone",
        defaultMessage: "Telephone",
      }),
    },
    {
      column: 2,
      field: "email",
      label: intl.formatMessage({
        id: "sites.edit.label.email",
        defaultMessage: "E-mail",
      }),
    },
  ];

  const Subscription = ({subscription, selected, onClick, current}) => {
    return <Paper
      style={{
        padding: "1rem 2rem",
        boxShadow: "none",
        borderWidth: 2,
        borderColor: selected ? theme.palette.primary.main : current ? theme.palette.warning.light : theme.palette.divider,
        borderStyle: "solid",
        cursor: (onClick && selected === false) ? 'pointer' : 'unset',
        height: "calc(100% + 8px)"
      }}
      onClick={onClick}
    >
      <Grid container spacing={1} alignContent="center" direction="column"
            style={{textAlign: "center", height: "100%"}}>
        <Grid item><Typography variant={"h2"}>{subscription?.title}</Typography></Grid>
        <Grid item><Typography variant={"h2"}>{intl.formatMessage({
          id: "billing.subscriptions.price",
          defaultMessage: "{price,number} {price_currency}"
        }, subscription)}<span style={{fontSize: "small", fontWeight: "normal"}}>{intl.formatMessage({
          id: "billing.subscriptions.price_period_monthly",
          defaultMessage: "/month"
        })}</span></Typography></Grid>
        <Grid item>{intl.formatMessage({
          id: "billing.subscriptions.storage_limit",
          defaultMessage: "Included {type}: {limit}"
        }, {
          ...subscription, limit: subscription?.limit < 0
            ? intl.formatMessage({id: "common.unlimited", defaultMessage: "unlimited"})
            : intl.formatNumber(subscription?.limit)
        })
        }<Tooltip interactive title={
          <span>
          {subscription?.type_id === '82'
            ? <FormattedMessage id="billing.subscriptions.storage_help"
                                defaultMessage="Storage values is the total amount of fields and saved entity values."/>
            : <FormattedMessage id="billing.subscriptions.processing_help"
                                defaultMessage="Credits is the total amount of times the system has processed data or performed and action."/>}&nbsp;
            <Link style={{color: theme.palette.info.light}} target="_blank"
                  to={"https://help.opendims.com/abonnementer/" + (subscription?.type_id === '82' ? 'data' : 'credits')}>
              <FormattedMessage id="common.link.read-more" defaultMessage="Read more"/>
            </Link>
        </span>
        }><HelpOutline style={{marginLeft: "0.25rem", fontSize: 14}}/></Tooltip></Grid>
        <Grid item style={{flexGrow: 1}}>{subscription?.feature_list?.split('\n').map((f, i) =>
          <Grid
            key={'subscription-' + subscription.id + '-feature-' + i}
            item
            container
            spacing={1}
            justifyContent="flex-start"
            alignItems="center">
            <Grid item>{f.substr(0, 2) === '* '
              ? <CheckSharp style={{fontSize: "1rem"}}/>
              : <Add style={{fontSize: "1rem"}}/>}</Grid>
            <Grid item>{f.substr(2)}</Grid>
          </Grid>)}
        </Grid>
        {onClick && <Grid item><Button
          variant="contained"
          color="primary"
          onClick={onClick}
        >{current ? intl.formatMessage({id: "common.button.choose-old"}) : intl.formatMessage({id: "common.button.choose"})}</Button></Grid>}
        {!onClick && !selected && <Grid item>
          <i><FormattedMessage id="billing.subscription.contact-for-downgrade"
                               defaultMessage="To downgrade your subscription, please contact us."/></i>
        </Grid>}
      </Grid>
    </Paper>
  };

  const getSubscriptionsOfType = (type_id) => {
    let subs = [];
    for (let k in subscriptions) {
      if (subscriptions[k].type_id === type_id) {
        subs.push(subscriptions[k]);
      }
    }
    return subs;
  }

  const selectSubscriptionOfType = (id, type_id) => {
    let subs = [...site.subscriptions.filter(sub => sub.type_id !== type_id)];
    subs.push({
      type_id: type_id,
      subscription_id: id,
      // If automation is allowed and there is a price, then enable automation, else disable it
      use_automation: subscriptions[id].automation_id && subscriptions[id].automation_id !== '88' && subscriptions[id].price > 0,
    });
    setSite({
      ...site,
      ['subscription_' + (type_id === '82' ? 'storage' : 'process')]: {...subscriptions[id]},
      subscriptions: subs
    });
    setShowSubscriptionSelect(false);
  }

  const allowAutomationOnSubscriptionType = (type_id, checked) => {
    setSite({
      ...site, subscriptions: site.subscriptions.map(s => {
        if (s.type_id === type_id) return {
          subscription_id: s.subscription_id,
          type_id: s.type_id,
          use_automation: checked,
        };
        return s;
      })
    });
  }

  const isSubscriptionsChanged = () => {
    if (originalSite.subscriptions?.length !== site.subscriptions?.length) {
      return true;  // there are different amount of subscriptions, that is a change
    }
    for (let i in site.subscriptions) {
      let found = originalSite.subscriptions.find(o => o.subscription_id === site.subscriptions[i].subscription_id);
      if (!found) {
        return true;  // we found a new subscription that is not in the original
      }
      if ((!found.use_automation && site.subscriptions[i].use_automation) || found.use_automation !== site.subscriptions[i].use_automation) {
        return true;  // the found same subscription has changed the automation flag
      }
    }
  }

  const isSiteChanged = () => {
    for (let k in site) {
      if (typeof site[k] === "string" && site[k] !== originalSite[k]) {
        return true;
      }
    }
    return false;
  }

  const isCompanyValid = () => {
    return (
      site.company?.length >= 6 &&
      site.address?.length >= 6 &&
      site.zip?.length >= 4 &&
      site.city?.length >= 3 &&
      site.country?.length >= 6 &&
      site.cvr?.length >= 8 &&
      site.contact?.length >= 8 &&
      site.email?.length >= 8
    );
  }

  const getAutomationLabel = (automation_id) => {
    switch (automation_id) {
      case '89':
        return intl.formatMessage({
          id: "billing.subscriptions.automation.renew",
          defaultMessage: "Automatically renew subscription if usage goes above the current limit"
        });
      case '88':
        return intl.formatMessage({
          id: "billing.subscriptions.automation.upgrade",
          defaultMessage: "Automatically upgrade subscription if usage goes above the current limit"
        });
      default:
        return 'Unknown automation_id: ' + automation_id;
    }
  }

  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item xs={12} style={{paddingRight: 0}}>
        <ConfirmDialog
          onClose={saveSubscriptions}
          open={confirmSubscriptions}
          title={intl.formatMessage({
            id: "billing.subscriptions.confirm.title",
            defaultMessage: "Please confirm your subscriptions"
          })}
          message={intl.formatMessage({
            id: "billing.subscriptions.confirm.message",
            defaultMessage: "Are you sure you want to save your changes to your subscriptions?"
          })}
        />
        <Dialog open={!!showSubscriptionSelect} maxWidth="lg" onClose={_ => setShowSubscriptionSelect(false)}>
          <DialogTitle disableTypography style={{
            fontSize: "x-large",
            textAlign: "center",
            fontWeight: "bold"
          }}>{intl.formatMessage({
            id: "billing.subscriptions.select",
            defaultMessage: "Select the subscription you wish to change to"
          })}<IconButton style={{float: "right"}} onClick={_ => setShowSubscriptionSelect(false)} size="small"><Close/></IconButton></DialogTitle>
          <DialogContent><Grid container spacing={2}
                               justifyContent="space-between">{getSubscriptionsOfType(showSubscriptionSelect).map((s, i) =>
            <Grid item xs={12} md={3} key={'subscription-' + s.id}>
              <Subscription
                subscription={s}
                selected={site.subscriptions.find(sub => sub.subscription_id === s.id) || false}
                current={!!originalSite.subscriptions.find(sub => sub.subscription_id === s.id)}
                onClick={(i > selectedSubscriptionIdxs[showSubscriptionSelect]
                  || (isSubscriptionsChanged() && !!originalSite.subscriptions.find(sub => sub.subscription_id === s.id)))
                  ? _ => selectSubscriptionOfType(s.id, showSubscriptionSelect) : null}
              />
            </Grid>
          )}</Grid></DialogContent>
        </Dialog>
        <ContainerCard>
          <CardHeader
            title={intl.formatMessage({
              id: "billing.subscriptions.heading",
              defaultMessage: "Your current subscriptions"
            }, {type: ''})}
          />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} container spacing={2}>
                <Grid item xs={12}>
                  <Typography><FormattedMessage
                    id="billing.subscriptions.type"
                    defaultMessage="Your current {type} subscriptions"
                    values={site.subscription_storage}
                  /></Typography>
                </Grid>
                <Grid item xs={12} style={{display: "none"}}>
                  <FormControlLabel
                    disabled={subscriptions[site.subscriptions?.find(s => s.type_id === '82')?.subscription_id]?.automation_id === '88'}
                    control={
                      <Switch
                        checked={site.subscriptions?.find(s => s.type_id === '82')?.use_automation || false}
                        onChange={e => allowAutomationOnSubscriptionType('82', e.target.checked)}
                        name="storage_automation"
                        color="primary"
                      />
                    }
                    label={getAutomationLabel(site.subscription_storage?.automation_id)}
                  />
                </Grid>
                <Grid item style={{position: "relative", width: "90%"}}>
                  <Subscription subscription={site.subscription_storage} selected={true}/>
                  <Fab
                    color="primary"
                    style={{position: "absolute", right: -12, bottom: -12}}
                    size="small"
                    onClick={_ => setShowSubscriptionSelect('82')}
                  ><Edit/></Fab>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6} container spacing={2} alignContent="flex-start">
                <Grid item xs={12}>
                  <Typography><FormattedMessage
                    id="billing.subscriptions.type"
                    defaultMessage="Your current {type} subscriptions"
                    values={site.subscription_process}
                  /></Typography>
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    disabled={subscriptions[site.subscriptions?.find(s => s.type_id === '83')?.subscription_id]?.automation_id === '88'}
                    control={
                      <Switch
                        checked={site.subscriptions?.find(s => s.type_id === '83')?.use_automation || false}
                        onChange={e => allowAutomationOnSubscriptionType('83', e.target.checked)}
                        name="process_automation"
                        color="primary"
                      />
                    }
                    label={getAutomationLabel(site.subscription_process?.automation_id)}
                  />
                </Grid>
                <Grid item style={{position: "relative", width: "90%"}}>
                  <Subscription subscription={site.subscription_process} selected={true}/>
                  <Fab
                    color="primary"
                    style={{position: "absolute", right: -12, bottom: -12}}
                    size="small"
                    onClick={_ => setShowSubscriptionSelect('83')}
                  ><Edit/></Fab>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
          <CardActions style={{justifyContent: "space-between"}}>
            <Grid container spacing={2} alignItems="center"
                  style={{color: theme.palette.warning.main}}>{isSubscriptionsChanged() && !isCompanyValid() &&
              <Chip color={"secondary"} icon={<Warning/>} label={
                <FormattedMessage id="billing.subscriptions.change-requires-information"
                                  defaultMessage="You have made changes to your subscriptions, but your company information has not been filled out yet!"/>
              }/>
            }</Grid>
            <Button
              disabled={!isSubscriptionsChanged() || !isCompanyValid() || isSavingSubs}
              startIcon={isSavingSubs ? <CircularProgress size={18}/> : null}
              variant="contained"
              color="primary"
              onClick={_ => setConfirmSubscriptions(true)}
            >{
              intl.formatMessage({id: "common.button.save"})
            }</Button>
          </CardActions>
        </ContainerCard>
      </Grid>
      <Grid item xs={12} style={{paddingRight: 0}}>
        <ContainerCard>
          <CardHeader
            avatar={<label
              title={intl.formatMessage({id: "sites.edit.label.logo", defaultMessage: "Click to set your logo"})}>
              <input type="file" accept="image/*" style={{display: "none"}}
                     onChange={e =>
                       handleFileUpload('logo', e.target.files[0])}/>
              <Badge
                color="primary"
                badgeContent={<span style={{cursor: "pointer"}}><Edit/></span>}
                anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
              >{(site.logo_data || site.logo_uri) ? <img alt={site.name}
                                                        src={site.logo_data ? site.logo_data : site.logo_uri}
                                                        style={{maxWidth: 240, maxHeight: 120, cursor: "pointer"}}/> :
                <Image style={{width: 120, height: 120, cursor: "pointer"}}/>}</Badge>
            </label>}
            title={<FormattedMessage
              id={"user-profile.company.heading"}
              defaultMessage={"Company information"}
            />}/>
          <CardContent>
            <EditForm
              validation={validation.site}
              isLoading={isSavingSite}
              setIsLoading={setIsSavingSite}
              data={site}
              setData={setSite}
              fields={fields}
              cols={2}/>
          </CardContent>
          <CardActions style={{justifyContent: "flex-end"}}>
            <Button
              disabled={!isSiteChanged() || isSavingSite}
              startIcon={isSavingSite ? <CircularProgress size={18}/> : null}
              variant="contained"
              color="primary"
              onClick={saveSite}
            >
              <FormattedMessage id={"common.button.save"}/>
            </Button>
          </CardActions>
        </ContainerCard>
      </Grid>
    </Grid>
  );
}
